<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員等級管理
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsCustomerList', params: { id: $route.params.id } }">等級會員清單</b-breadcrumb-item>

            <b-breadcrumb-item active>會員異動歷程</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="isFetching">
      <b-card>
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員異動歷程</h4>

        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="customers"
              :fields="fields"
            >
              <template #cell(name)="data">
                <div class="d-flex">
                    <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.customer.id } }" >
                    <b-avatar
                        :src="data.item.customer.avatar_url"
                        variant="secondary"
                        size="2rem"
                    ></b-avatar>
                    {{ data.item.customer.name | hiddenString(20) }}
                    </router-link>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchCustomers"
          ></b-pagination>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import customerLevelApi from "@/apis/customer-levels";
import moment from "moment";

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  components: { BAvatar },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isFetching: false,
      customerLevel: {
        name: "",
      },
      customers: [],
      fields: [
        { key: "level.name", label: "等級名稱" },
        { key: "name", label: "LINE 名稱" },
        {
          key: "created_at",
          label: "加入時間",
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
          }
        },
        {
          key: "disabled_at",
          label: "離開時間",
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
          }
        }
    ],
    };
  },

  mounted() {
    this.fetchCustomerLevel()
    this.fetchCustomers()
  },

  methods: {
    async fetchCustomerLevel() {
      const { data } = await customerLevelApi.show(
        this.$route.params.id
      );
      this.customerLevel = data.data
    },
    async fetchCustomers() {
      try {
        this.isFetching = true;
        const { data } = await customerLevelApi.getLogs(this.$route.params.id, {
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.customers = data.data
        this.totalRows = data.meta.total
        console.log(this.customers)
      } catch (e) {
        console.log(e)
      }
      this.isFetching = false
    }
  }
}
</script>

<style></style>
